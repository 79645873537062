import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

export default function Faq() {
    const tk = 'sksjw16uAbcl3kPHVG9aNwn4XFGtwv9DZVkYmgYa0kIsxxWht10rEvSnIOHHGUSlTqBqBTZGI9nNOCMNTOnDZEQmVXNztPuv3BfiM0821C2xJ8nDON0RkTbW3tA5aU1kG1lADMyXG8H82UHUGB97CyxD28GLkAVQlSlT8ZtswLbgvjlJk4bB';


    const [faqData, setFaqData] = useState<any>();
    const [loaded, setLoaded] = useState<boolean>(false);


    useEffect(() => {
        axios.get('https://xsqzn871.api.sanity.io/v2021-06-07/data/query/intra?query=*[_type==%22faq%22]', { headers: { "Authorization": `Bearer ${tk}` } })
            .then(response => {
                console.log(response.data);
                setFaqData(response.data.result);
                setLoaded(true);
            }, error => {
                console.log(error);
            });
    }, [])

console.log('test');

    return (
        <>
        <h1 className="mt-4">FAQ</h1>
            <h4 className="mt-4">Ansatte</h4>
            <Accordion>
                {
                    loaded && faqData.map((value: any, i: number) => {
                        return (
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <Accordion.Header>{value.question}</Accordion.Header>
                                <Accordion.Body>
                                    {value.answer[0].children[0].text}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
            <h4 className="mt-4">Selskap</h4>
            <Accordion>
                {
                    loaded && faqData.map((value: any, i: number) => {
                        return (
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <Accordion.Header>{value.question}</Accordion.Header>
                                <Accordion.Body>
                                    {value.answer[0].children[0].text}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
            <h4 className="mt-4">Kunder</h4>
            <Accordion>
                {
                    loaded && faqData.map((value: any, i: number) => {
                        return (
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <Accordion.Header>{value.question}</Accordion.Header>
                                <Accordion.Body>
                                    {value.answer[0].children[0].text}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
            <h4 className="mt-4">Sikkerhet</h4>
            <Accordion>
                {
                    loaded && faqData.map((value: any, i: number) => {
                        return (
                            <Accordion.Item key={i} eventKey={`${i}`}>
                                <Accordion.Header>{value.question}</Accordion.Header>
                                <Accordion.Body>
                                    {value.answer[0].children[0].text}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>

        </>
    )
}
