import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Faq from './pages/faq';
import Dashboard from './pages/Dashboard';
import Employees from './pages/Employees';


function App() {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Intra</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Dashboard</Nav.Link>
            <Nav.Link href="/employees">Ansatte</Nav.Link>
            <Nav.Link href="/faq">Ofte stilte spørsmål</Nav.Link>
            <Nav.Link href="/faq">Kundeoversikt</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container>
        <Router>
          <Routes>
            <Route path='/faq' element={<Faq />} />
            <Route path='/employees' element={<Employees />} />
            <Route path='/' element={<Dashboard />} />
          </Routes>
        </Router>
      </Container>
    </>
  );
}

export default App;
