import React from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';

export default function Dashboard() {
    return (
        <div>
            <Container>
                <Row>
                <h1 className="mt-4">Dashboard</h1>
                    <Col>
                <Card style={{marginTop: '1rem' }}>
                    <Card.Body>
                        <Card.Title>Velkommen til Intra</Card.Title>
                        <Card.Text>
                            Inta er inngangsportalen til INEVOS ressurser. Her kan du regelmessig gå inn å sjekke nyttige lenker, ofte stilte spørsmål, se andre ansatte og rollefordeling i INEVO.
                        </Card.Text>
                    </Card.Body>
                </Card>
                </Col>
                <Col>
                <Card style={{ marginTop: '1rem' }}>
                    <Card.Body>
                        <Card.Title>Nyttige lenker</Card.Title>
                        <Card.Text>
                            <ul>
                                <li><a href="#" target="_blank">Google Meet (Mandagsmøte)</a></li>
                                <li><a href="#" target="_blank">Ukesnotater (Mandagsmøte)</a></li>
                                <li><a href="#" target="_blank">Resulty (Rapporter på OKR)</a></li>
                                <li><a href="#" target="_blank">Ferieoversikt</a></li>
                                <li><a href="#" target="_blank">INEVO MAL (Slides)</a></li>
                                <li><a href="#" target="_blank">INEVO MAL (Docs)</a></li>
                                <li><a href="#" target="_blank">Din kalender</a></li>
                            </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
                </Col>
                </Row>
            </Container>
        </div>
    )
}
