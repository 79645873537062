import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';

export default function Employees() {

    const tk = 'sksjw16uAbcl3kPHVG9aNwn4XFGtwv9DZVkYmgYa0kIsxxWht10rEvSnIOHHGUSlTqBqBTZGI9nNOCMNTOnDZEQmVXNztPuv3BfiM0821C2xJ8nDON0RkTbW3tA5aU1kG1lADMyXG8H82UHUGB97CyxD28GLkAVQlSlT8ZtswLbgvjlJk4bB';


    const [employeeData, setEmployeeData] = useState<any>();
    const [loaded, setLoaded] = useState<boolean>(false);


    useEffect(() => {
        axios.get('https://xsqzn871.api.sanity.io/v2021-06-07/data/query/intra?query=*[_type==%22employee%22]', { headers: { "Authorization": `Bearer ${tk}` } })
            .then(response => {
                console.log(response.data.result);
                setEmployeeData(response.data.result);
                setLoaded(true);
            }, error => {
                console.log(error);
            });
    }, [])


    return (
        <div>
            <Container>
                <Row>
                    <h1 className="mt-4">Ansatte</h1>



                    {
                        loaded && employeeData.map((value: any, i: number) => {
                            return (
                                <Col key={i}>
                                    <Card>
                                        <Card.Img variant="top" src={'https://cdn.sanity.io/images/xsqzn871/intra/' + value.image.asset._ref + '.jpg'} />
                                        <Card.Body>
                                            <Card.Title>{value.fullName}</Card.Title>
                                            <Card.Text>
                                                {value.position} <br />
                                                {value.officeLocation}<br />
                                                {value.phoneNumber}<br />
                                                {value.email}<br />
                                                {value.timeCommitment}<br />
                                                <a href={value.linkedinUrl} target="_blank">LinkedIn</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }


                </Row>
            </Container>
        </div>
    )
}
